<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="iconColor"
        fab
        depressed
        x-small
        v-bind="attrs"
        v-on="on"
        @click="execute"
      >
        <v-icon>{{ active ? 'dark_mode' : 'light_mode' }}</v-icon>
      </v-btn>
    </template>
    <span>Alterar tema</span>
  </v-tooltip>
</template>

<script>
import { themes } from '@/mixins/theme';

export default {
  mixins: [themes],

  data: () => ({
    active: false
  }),

  computed: {
    iconColor() {
      const isDark = this.$vuetify.theme.isDark;
      return isDark ? 'primary darken-3' : 'primary darken-1';
    }
  },

  created() {
    this.active = this.theme();
  },

  methods: {
    execute() {
      const theme = (this.$vuetify.theme.dark = !this.$vuetify.theme.dark);
      localStorage.setItem('biobe_theme', theme);
      this.active = this.theme();
    }
  }
};
</script>
